<style>
    .navbar-item {
      color: #444;
    }
    .topbar {
      background-color: rgb(34, 32, 32);
      color: #fff;
    }
  </style>
  
  <nav class="z-depth-0 topbar" role="navigation">
    <div class="nav-wrapper container">
      <a id="logo-container" href="/" class="brand-logo white-text">
      </a>
      <ul class="left hide-on-med-and-down">
        <li>
          <a href="/" rel="nofollow" class="navbar-item active white-text">Home</a>
        </li>
        <li>
          <a class="navbar-item white-text" href="/faq">About</a>
        </li>  
        <li>
            <a class="navbar-item white-text" href="/docs">Guide</a>
        </li>      
      </ul>
    </div>
  </nav>
  