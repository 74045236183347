<style>
  h5 {
    font-weight: 200;
  }
</style>


<div class="blue-grey darken-4 white-text">  
  <div class="container">
  <div class="section">
    <div class="row">
      <div class="col s12">
        <h3 class="center-align">What the app can do</h3>
      </div>
    </div>
    <div class="row">
      <div class="col s12 m6">
        <h5>Portsmouth yardstick average lap time timing</h5>
        <h5>Built in redundancy - ability to connect two apps</h5>
        <h5>Single fleet mode</h5>
        <h5>Multi fleet mode</h5>
        <h5>Pursuit race setup - delta calculations</h5>
        <h5>Pursuit race timing</h5>
        <h5>Immediate results</h5>
        <h5>Charts of race results (e.g. position at each lap)</h5>
        <h5>Audio voice alerts or beeps</h5>
        <h5>Customizable pre-start period (0-60 seconds)</h5>
        <h5>Race tally/sign on/off</h5>
        <h5>Sort by: predicted lap time, corrected time, PY, helm, class</h5>
        <h5>Multiple start sequences - 5,4,1,Go, 3,2,1,Go and 1,30,10,Go or used defined</h5>
        <h5>Sync function</h5>
        <h5>Fast filtering by sail number</h5>
        <h5>One click 'Add Lap' function</h5>
        <h5>Ability to undo a lap (double tap the laps list or click popup)</h5>
        <h5>'Pre-select' function to capture racers up next</h5>
        <h5>Light and dark theme</h5>
        <h5>Individual recall handling  - mark competitors OCS after start</h5>

      </div>

      <div class="col s12 m6">
        <h5><a href='cloud' alt='Sailrace Cloud'>Single click publish to competitors</a></h5>
        <h5>Ability to edit result after the race</h5>
        <h5>Switch sailor feature (multiple sailors sharing boat)</h5>
        <h5>Series management</h5>
        <h5>Competitors can have different handicaps in each series</h5>
        <h5>Easy import of competitors from CSV file</h5>
        <h5>Export function to CSV which is compatible with Sailwave</h5>
        <h5>Class/handicap management with easy import via CSV file</h5>
        <h5>Update handicaps of all competitors in a series to latest</h5>
        <h5>Update of handicaps to latest with a single click</h5>
        <h5>Export/import of the database for backup and sharing</h5>
        <h5>Stores data on every change to a local database for reliability</h5>
        <h5>Competitor pre-selection</h5>
        <h5>Option to keep the screen on during races</h5>
        <h5>Minimal power consumption and hardware requirements (works on cheap tablets)</h5>
        <h5>Works 100% offline with no internet required (i.e. airplane mode)</h5>
      </div>
    </div>
  </div>

  </div>
</div>
