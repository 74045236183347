<script>
  import { Navigate } from 'svelte-router-spa'

  import LoginForm from './form.svelte'
  import { currentUser } from '../../../stores/current_user'


  let showPage = false

  $: if (!$currentUser) {
    showPage = false
  } else {
    if ($currentUser.id === 0) {
      navigateTo('/admin')
    } else {
      showPage = true
    }
  }
</script>

<style>
  .mt10 {
    padding-top: 1rem;
  }
</style>

<main>
  <div class="container">
    <div class="row">
      <div class="col s8 offset-s2">
        <div class="card card-login">
          <div class="card-login-splash">
            <div class="wrapper">
              <h3 class="center-align mt10">Admin login</h3>
            </div>
          </div>
          <div class="card-content">
            <span class="card-title center-align">Log in to your club account</span>
            <LoginForm />
            <!-- <p>
              <Navigate to="signup">Need an account? Create one free.</Navigate>
            </p> -->
            <p>Need an account? Create one free in the Sailrace Dinghy Race timer App.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
