<script>
  import { Navigate } from 'svelte-router-spa'
</script>

<style>
  .not-found {
    margin: 3rem;
  }
</style>

<div class="container">
<section class="not-found">
  <h1 class="title">Not found</h1>
  <h3 class="subtitle">That link does not exist.</h3>
  <h5>
    Return to the
    <Navigate to="/">homepage</Navigate>
  </h5>
</section>
</div>