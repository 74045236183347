
<script>
  import SidebarMenu from './menu.svelte'

  import { currentUser } from '../../../../stores/current_user'

  let adminMenuItems = [
    { to: '/admin/', icon: 'dashboard', title: 'Dashboard' },
    { to: '/admin/teams', icon: 'group_work', title: 'Teams' },
    { to: '/admin/employees', icon: 'person_pin', title: 'Employees' }
  ]

  let userMenuItems = [
    { to: '/admin/', icon: 'dashboard', title: 'Dashboard' },
    { to: '/admin/teams/', icon: 'group_work', title: 'Teams' },
    { to: '/admin/employees/', icon: 'person_pin', title: 'Employees' }
  ]

  console.log('currentUser', $currentUser);
</script>

<style>
  .sidenav-fixed {
    margin-top: 64px;
  }
</style>


<ul id="sidenav-left" class="sidenav sidenav-fixed">
  {#if currentUser.isAdmin}
    <SidebarMenu items={adminMenuItems} />
  {:else}
    <SidebarMenu items={userMenuItems} employeeId={currentUser.id} />
  {/if}
</ul>
