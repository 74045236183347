
<style>
.bottombar {
  background-color: rgb(34, 32, 32);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  padding: 20px;
}
</style>

<footer class="bottombar darken-4">
    <div class="col s6">
      <div class="cyan-text text-lighten-5">Sailrace dinghy race timer</div>
      <div class="cyan-text text-lighten-5">Say hello <a href="mailto:sailrace.app@gmail.com">sailrace.app@gmail.com</a></div>
    </div>
    <div class="col s6 right-align">
      <span>
          <a href="/privacy" class="cyan-text">Privacy</a>
      </span>
    </div>
</footer>
